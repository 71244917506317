import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { User } from 'src/app/core/models/user';
import { Tenant } from '../../AppEnums/Tenant';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  // baseUrl: string = environment.API_b2c;
  // showHideLogin = new EventEmitter<any>();

  public userSubject: BehaviorSubject<User>;
  public user: Observable<User>;

  constructor(private apiService: ApiService) {
    this.userSubject = new BehaviorSubject<User>({});
    this.user = this.userSubject.asObservable();
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  public saveUserInfo(param: object): Observable<any> {
    return this.apiService
      .post(environment.baseUrl + `api/Accounts/SignUp`, { ...param, tenantId: Tenant.WAJED })
      .pipe(
        map((data) => {
          return data;
        }),
      );
  }

  public doSignIn(param: object): any {
    return this.apiService
      .post(environment.baseUrl + `api/Auth/login`, {
        ...param,
        tenantRefNumber: '',
        loginAs: 'ClientUser',
      })
      .pipe(
        map((user) => {
          let sampleUser = {
            id: user?.user?.id,
            username: user?.user?.firstName + '' + user?.user?.lastName,
            firstName: user?.user?.firstName,
            lastName: user?.user?.lastName,
            jwtToken: user?.accessToken?.token,
            refreshToken: user?.refreshToken,
          };
          this.userSubject.next(sampleUser);
          return user;
        }),
      );
  }

  public getUserProfileData(param: object): any {
    return this.apiService.get(environment.baseUrl + `api/Accounts/EndUser/` + param).pipe(
      map((data) => {
        return data;
      }),
    );
  }

  public updateUserProfileData(param: object, uid: number): Observable<any> {
    return this.apiService
      .put(environment.baseUrl + `api/Accounts/UpdateEndUser/v3/` + uid + `/` + uid, param)
      .pipe(
        map((data) => {
          return data;
        }),
      );
  }

  // get all previous orders

  public getPreviousBookings(param: any, param1: any, type: any): any {
    // return this.apiService.get(environment.API_EcoSave + `GetOrderDetailsQueue/${param}`).pipe(
    //   map((data) => {
    //     return data;
    //   })
    // );

    try {
      return this.apiService.get(
        environment.API_EcoSave + `api/Common/GetOrderDetailsQueue/${param}/${param1}/${type}`,
      );
    } catch (error: any) {
      return error;
    }
  }

  // previous order details

  public getOrderDetails(param: object): any {
    return this.apiService
      .get(environment.API_EcoSave + `api/Common/GetOrderDetails/` + param + `/INR/Flight`)
      .pipe(
        map((data) => {
          return data;
        }),
      );
  }

  public getCountries(param?: object): Observable<any> {
    return this.apiService.get(environment.API_Supplier + `Country`).pipe(
      map((data) => {
        return data;
      }),
    );
  }

  public getCityByCountry(param?: object): Observable<any> {
    return this.apiService.get(environment.API_Supplier + `City/` + param).pipe(
      map((data) => {
        return data;
      }),
    );
  }

  public confirmSignUp(param?: any): Observable<any> {
    return this.apiService
      .post(
        environment.baseUrl +
          `api/Accounts/ConfirmEmail`, param
      )
      .pipe(
        map((data) => {
          return data;
        }),
      );
  }

  public forgotPassword(param: object): Observable<any> {
    return this.apiService
      .post(environment.baseUrl + `api/Accounts/ForgotPassword`, { ...param })
      .pipe(
        map((data) => {
          return data;
        }),
      );
  }

  public ResetPassword(param: object): Observable<any> {
    return this.apiService.post(environment.baseUrl + `api/Accounts/ResetPassword`, param).pipe(
      map((data) => {
        return data;
      }),
    );
  }

	public addReview(reviewData: object): Observable<any> {
    return this.apiService.post(`${environment.API_EcoSave}api/Reviews/add`, reviewData).pipe(
      map((response) => {
				return response
			}),
    );
  }

	public subscribeToNewsletter(newsletterData: object): Observable<any> {
		return this.apiService.post(`${environment.API_EcoSave}api/Newsletter/subscribe`, newsletterData).pipe(
      map((response) => {
				return response
			}),
    );
	}
}
