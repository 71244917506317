export enum FareTag {
  BaseFare = 'Base',
  TotalDiscount = 'TotalDiscount',
  TotalMarkup = 'TotalMarkup',
  Markup = 'Markup',
  Commission = 'Commission',
  Breakfast = 'Breakfast',
  BreakfastTax = 'BreakfastTax',
  Tax = 'Tax',
  None = 'None',
  Fee = 'Fee',
  Misc = 'Misc',
  GST = 'GST',
  Other = 'Other',
  GuestCharge = 'GuestCharge',
  ServiceCharge = 'ServiceCharge',
  Vat = 'VAT',
}

export enum SuppRateType {
  NetRate = 'N.R',
  CommissionRate = 'C.R',
  BuySelling = 'B&S',
  TBOH = 'TBOH',
}
