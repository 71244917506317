import { Component } from '@angular/core';
import { GlobalService } from './shared';
import { PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'AlrajhiUmrah';

  constructor(
    private globalService: GlobalService,
    private translateService: TranslateService,
    private primengConfig: PrimeNGConfig,
  ) {}

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.setCurrencyOnPageReload();

    //Localization for primeng components
    //NOTE - To add support for all the components, we need to add the translation for each component in the languages json file.
    this.translateService.onLangChange.subscribe(() => {
      this.translateService
        .get('primeng')
        .subscribe((res) => this.primengConfig.setTranslation(res));
    });
  }

  setCurrencyOnPageReload() {
    const selectedCurrency = localStorage.getItem('wajedCurrency')
      ? JSON.parse(localStorage.getItem('wajedCurrency') as string).name
      : '';
  }
}

export type ShoppingDropdownData = {
  city: string;
  country: string;
  countryCode: string;
  id: number;
  name: string;
  profileImage: string;
  profileImageArr: null | any[];
  profileImageBase64: null | string;
  serviceType?: string;
};
